<template>
    <div class="page pd page-container">
        <div class="profile-container">
            <div class="panel-left">
                <div class="panel mgh">
                    <div class="header">Assigned User Groups</div>
                    <div class="content">
                        <div class="empty" v-if="userGroups.length === 0">
                            There currently is no assigned User Group.
                        </div>

                        <div v-else :data-tooltip="userGroups[0].attributes.description">
                            {{userGroups[0].attributes.name}}
                        </div>
                    </div>
                </div>

                <assigned-players :players="players"/>
            </div>

            <div class="gutter"/>

            <profile class="panel-right" :user="user" :company="company"></profile>
        </div>
    </div>
</template>

<script>
import Profile from "@/views/app/your-account/components/profile";
import AssignedPlayers from "@/views/app/your-account/components/assigned-players";

export default {
  name: "all",
  components: {AssignedPlayers, Profile},

  data() {
    return {
      user: {},
      company: {},
      userGroups: [],
      players: [],
    }
  },

  mounted() {
    this.$talker.api('/users/profile')
      .then((res) => {
        this.user = res.data.data.attributes;
        this.company = res.data.data.company.data.attributes

        // players
        this.players = res.data.data.players.data;

        // User Group
        this.userGroups.push(res.data.data.user_group.data)
      })
  }
}
</script>

<style scoped lang="scss">

.profile-container {
    display: flex;

    .panel-left {
        flex: 2;

        .content {
            text-align: center;
        }
    }
    .gutter {
        margin: 1em;
    }
    .panel-right {
        flex: 1;
        display: flex;
        height: fit-content;
    }
}

@media only screen and (max-width: 720px) {
    .profile-container {
        flex-flow: column-reverse;
        margin-bottom: 4em;

        .gutter {margin: 0;}
    }
}

</style>