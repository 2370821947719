<template>
    <div class="panel mgh">
        <div class="header">Your Profile</div>

        <div class="content">
            <div class="line">
                <div class="title">
                    <awesome-icon class="icon" icon="user"/>
                    Display Name
                </div>
                <p>{{ user.first_name }} {{ user.last_name }}</p>
            </div>

            <div class="line">
                <div class="title">
                    <awesome-icon class="icon" icon="envelope"/>
                    Email/ UserName
                </div>
                <p>{{ user.email }}</p>
            </div>

            <div class="line">
                <div class="title">
                    <awesome-icon class="icon" icon="briefcase"/>
                    Company
                </div>
                <p>{{ company.name }}</p>
            </div>

            <div class="line" v-if="readableLastLogin">
                <div class="title">
                    <awesome-icon class="icon" icon="calendar"/>
                    Last login date
                </div>
                <p>{{ readableLastLogin }}</p>
            </div>

            <div class="line">
                <router-link class="button" to="/app/account/change-password">
                    <awesome-icon class="icon" icon="lock-open"/>
                    Change Password
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import toDateHuman from "@/filters/toDateHuman";

export default {
  name: "profile",
  props: {
    user: {required: true, type: Object},
    company: {required: true, type: Object}
  },
  computed: {
    readableLastLogin() {
      if (this.user.last_login) {
        return toDateHuman(this.user.last_login);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.panel {
    display: flex;
    flex-flow: column;
    height: auto;
    min-width: 340px;
}

.content {
    padding: 1em 0 2em;
}

.line {
    text-align: center;
    padding: 1em;
    margin: 0 2em;
    border-bottom: 1px solid #eee;

    .title {
        margin-bottom: .5em;

        .icon {
            color: black;
        }
    }
}

.button .icon {
    color: #FFF;
    margin-right: .5em;
}

</style>