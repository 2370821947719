<template>
    <div>
        <div v-if="players.length > amount" class="search-wrapper">
            <sc-search @searched="searched"/>
        </div>

        <div class="panel mgh" v-if="ready">
            <div class="header">Assigned Players</div>
            <div class="content">

                <div class="empty" v-if="paginatedPlayers.length === 0">
                <span v-if="search.length > 0">
                    There are no results available.
                </span>
                    <span v-else>
                    There are currently no assigned players.
                </span>
                </div>

                <router-link v-else v-for="p in paginatedPlayers" :to="`/app/players/${p.id}/edit`"
                             :key="`assigned-player-${p.id}`"
                             class="player-link"
                             :class="{tooltip: p.attributes.description}" :data-tooltip="p.attributes.description">
                    {{ p.attributes.name }}
                </router-link>

            </div>

            <sc-paginate :data="paginateData" @changePage="changePage"/>
        </div>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "assigned-players",
  components: {ScSearch},
  props: {
    players: {required: true},

  },
  data() {
    return {
      ready: false,
      amount: 15,
      current_page: 1,
      search: ''
    }
  },
  computed: {
    filteredPlayers() {
      if ([undefined, ''].includes(this.search)) {
        return this.players;
      } else {
        return this.players.filter((p) => p.attributes.name.toLowerCase().includes(this.search.toLowerCase()));
      }
    },

    paginateData() {
      return {
        total: this.filteredPlayers.length,
        current_page: this.current_page,
        last_page: Math.ceil(this.filteredPlayers.length / this.amount)
      }
    },

    paginatedPlayers() {
      let offset = this.current_page * this.amount - this.amount;
      return this.filteredPlayers.slice(offset, offset + this.amount);
    }
  },

  watch: {
    players() {
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.ready = true;
    })
  },

  methods: {
    searched(val) {
      this.search = val;
      this.current_page = 1;
    },

    changePage(target) {
      switch (target) {
        case 'next':
          this.current_page++;
          break;
        case 'previous':
          this.current_page--;
          break;
        case 'first':
          this.current_page = 1;
          break;
        default: {
          this.current_page = target;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.content {
    text-align: center;

    .player-link {
        display: block;
        padding: .5em 1em;
        border-bottom: 1px solid #eee;
        color: #a94442;
        cursor: pointer;

        &:hover {
            color: #843534;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.search-wrapper {
    margin-top: 2em;
    margin-bottom: -1em;
    display: flex;
    justify-content: flex-end;
}

</style>